import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

function PepoStatic(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('./models/pepo_static.glb')
  const { actions } = useAnimations(animations, group)
  console.log('rendered pepo', props)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Pepo" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <primitive object={nodes.mixamorigHips} />
          <skinnedMesh 
            name="pepo" 
            geometry={nodes.pepo.geometry} 
            material={materials['Material.009']} 
            skeleton={nodes.pepo.skeleton} 
          />
        </group>
      </group>
    </group>
  )
}

const PepoStatics = () => {  
  const pepoStaticData = {
    pepoPositions: [
      [0, 0, 0],
      [2, 0, 0],
      [4, 0, 0],
    ]
  };

  const platforms = pepoStaticData.pepoPositions.map((position, index) => (
    <PepoStatic
      key={index}
      position={position}
    />
  ));

  return <>{platforms}</>;
};


export default PepoStatics;
