/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.5 gun.glb
*/

import React, { useRef, useState,useEffect} from 'react'
import { useGLTF } from '@react-three/drei'
import { Sphere } from "@react-three/drei";
import { useSphere } from "@react-three/cannon";
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

export function Gun(props) {
  const { nodes, materials } = useGLTF('./models/gun.glb')
  return (
    <group {...props} dispose={null} scale={[0.5,0.5,0.5]} >
      <mesh geometry={nodes.Sphere.geometry} material={materials['Material.001']} />
    </group>
  )
}

useGLTF.preload('./models/gun.glb')

function Bullet({ initialPosition, args, ...props }) {
  const positionRef = useRef(new THREE.Vector3(...initialPosition));
  const [ref, api] = useSphere(() => ({
    type: "kinematic",
    mass: 1000,
    args: args,
    position: initialPosition,
    userData: { type: 'obstacle' },
    ...props,
  }));

  const speed = 0.05;

  useFrame(() => {
    const newPosition = positionRef.current.clone();
    newPosition.z += speed;

    positionRef.current = newPosition;
    api.position.set(newPosition.x, newPosition.y, newPosition.z);
  });

  useEffect(() => {
    const respawnTimer = setInterval(() => {
      console.log("Resetting bullet position");

      // Reset the position back to initial position using ref
      positionRef.current.set(...initialPosition);
      api.position.set(initialPosition[0], initialPosition[1], initialPosition[2]);
    }, 5000); // 4000ms for 4 seconds

    return () => clearInterval(respawnTimer); // Cleanup timer on component unmount
  }, [api, initialPosition]);

  return (
    <Sphere ref={ref} position={positionRef.current.toArray()} args={[0.25, 32, 32]}>
      <meshStandardMaterial color="yellow"/>
    </Sphere>
  );
}

const Guns = ({ material,data }) => {

  const platforms = Object.values(data.gun).map((object, index) => {

    const position = [object.location[0], object.location[2], object.location[1]]

    const args = [1, 1, 1]; 


    return (
      <>
      <Gun
        key={index}
        position={position}
        args={args}
        material={material}
        data={data}
      />
      <Bullet initialPosition={position} />

      </>

      
    );
  });

  return <>{platforms}</>;
};

export default Guns;