const useHighScoreSubmit = () => {
  var bodyLog 
  const sendHighScore = async (highScore) => {
    try {
      const response = await fetch('https://puexnvxxje.execute-api.eu-north-1.amazonaws.com/prod/highscores', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
          'origin': 'http://localhost:3000/',
        },
        body: JSON.stringify(highScore),
      });

      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      } else {
        const jsonResponse = await response.json();
        console.log(jsonResponse);
        console.log(response.body)
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log()

  return [sendHighScore];
};
export default useHighScoreSubmit