/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 podium.glb --transform 
Files: podium.glb [2.94KB] > podium-transformed.glb [1.04KB] (65%)
*/

import React, { useRef,useMemo } from 'react'
import { useGLTF,Text } from '@react-three/drei'
import PepoStatic from '../Character/Pepo_Static'
import { AnimationMixer } from 'three'

export function Podium({data,...props}) {
  const { nodes, materials } = useGLTF('./models/podium.glb')
  const mixer = useMemo(() => new AnimationMixer(), [])
  const actions = useRef({}).current;

  console.log(data)
  return (
    <>
    <group {...props} dispose={null} scale={[0.5,0.5,0.5]}>
      <mesh geometry={nodes.Cube.geometry} material={nodes.Cube.material} position={[2, 1.1, 8]} />
    </group>
    <group >
    <Text
          color="gold"
          anchorX="left"
          anchorY="left"
          maxWidth={80}
          lineHeight={1}
          position={[1.25,1.8,3.21]}
          fontSize={0.35}
          rotation={[0, Math.PI, 0]}
      >
        Biff
    </Text>
    <Text
          color="silver"
          anchorX="left"
          anchorY="left"
          maxWidth={80}
          lineHeight={1}
          position={[0.5,1,3.21]}
          fontSize={0.355}
          rotation={[0, Math.PI, 0]}
      >
        Joejoemaster
    </Text>
    <Text
          color="brown"
          anchorX="left"
          anchorY="left"
          maxWidth={80}
          lineHeight={1}
          position={[3,0.9,3.21]}
          fontSize={0.35}
          rotation={[0, Math.PI, 0]}
      >
        Nad
    </Text>
    </group>
    </>

  )
}

useGLTF.preload('/podium-transformed.glb')

export default Podium;