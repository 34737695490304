/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 statue.glb 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { MeshStandardMaterial } from 'three';

function Statue(props) {
  const { nodes, materials } = useGLTF('./models/statue.glb')
  const goldMaterial = new MeshStandardMaterial({ color: 0xD4AF37 , metalness: 0.7, roughness: 0.2 });
  const silverMaterial = new MeshStandardMaterial({ color: 0xC0C0C0, metalness: 0.7, roughness: 0.2 });
  const bronzeMaterial = new MeshStandardMaterial({ color: 0xCD7F32, metalness: 0.7, roughness: 0.4 });
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.statue.geometry} material={silverMaterial} rotation={[Math.PI / 2, 0, 3]}  position={[-0.5,1.2,4]} scale={[1.25,1.25,1.25]}/>
      <mesh geometry={nodes.statue.geometry} material={goldMaterial} rotation={[Math.PI / 2, 0, 3]}  position={[1,2.05,4]} scale={[1.5,1.5,1.5]}/>
      <mesh geometry={nodes.statue.geometry} material={bronzeMaterial} rotation={[Math.PI / 2, 0, 3]}  position={[2.5,1,4]} />
    </group>
  )
}

useGLTF.preload('/statue.glb')

export default Statue
